import * as React from "react";
import Button from "../components/button";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Cta from "../components/cta";

const LocationPage = () => (
  <Layout backgroundColor="salmon">
    <Seo title="Location" />

    <h1>Location</h1>
    <address>
      <a href="https://fortmason.org/venue/firehouse/">
        The Firehouse at Fort Mason
      </a>
      <br />
      2 Marina Blvd
      <br />
      San Francisco, CA 94123
    </address>
    <p>
      Our ceremony will be just outside facing the Bay, and the reception will
      take place inside The Firehouse at Fort Mason.
    </p>

    <p>
      <Button
        text="Apple Maps"
        url="https://maps.apple.com/?address=99%20Marina%20Blvd,%20San%20Francisco,%20CA%20%2094123,%20United%20States&auid=10316267161788674522&ll=37.807731,-122.429689&lsp=9902&q=Fort%20Mason%20Firehouse&_ext=ChoKBQgEEOIBCgQIBRADCgUIBhDfAQoECAoQABImKbyy0cJ55kJAMat2jqohnF7AOTqI9x6g50JAQfUA3mJnm17AUAQ%3D"
      />
      <Button
        text="Google Maps"
        url="https://www.google.com/maps/place/The+Firehouse+at+Fort+Mason+Center+for+Arts+%26+Culture/@37.8076013,-122.430612,18.81z/data=!4m13!1m7!3m6!1s0x808580d889ed2751:0x55e925e57bb97110!2s2+Marina+Blvd,+San+Francisco,+CA+94123!3b1!8m2!3d37.8053418!4d-122.4324908!3m4!1s0x0:0x550354ac54971667!8m2!3d37.8077196!4d-122.4296362"
      />
    </p>

    <h2>Parking</h2>
    <p>
      The venue is part of a large complex and there is plenty of paid parking.
      Our event will be toward the back of the complex, facing the Bay.
    </p>
    <Cta url="/schedule/" text="Check out the schedule" />
  </Layout>
);

export default LocationPage;
